/* You can add global styles to this file, and also import other style files */
@import url(../node_modules//quill/dist/quill.snow.css);
@import url(../node_modules//quill/dist/quill.core.css);
html,
body {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Roboto Flex", "Helvetica Neue", sans-serif;
}

.p-column-data {
  display: table-cell;
}

.wrap-white {
    position: relative;
    padding: 10px;
    background-color: white;
    border-radius: 50%;
}

.button-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .button-label{
        text-align: center;
        width: 80%;
    }
}

.mat-dialog-container {
    border-radius: 30px !important;
  }

.error-text{
    color: #ff4032;
}

.transfer-class-dialog{
    mat-dialog-container {
        overflow: unset !important;
    }
}

.overflow-class-dialog{
  mat-dialog-container {
      overflow: unset !important;
  }
}

  .glowing {
    animation: glowing 1300ms ease-in-out infinite alternate;
    margin-bottom: 20px;
    margin-top: -20px;
    transition: margin 700ms;
  }

  .glowing-button{
    margin-top: -15px;
  }
  @keyframes glowing {
    0% {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #553947, 0 0 20px #553947, 0 0 30px #553947, 0 0 30px #553947, 0 0 30px #553947;
    }
    50% {
      box-shadow: 0 0 10px #fff, 0 0 20px #c5a3b4, 0 0 20px #c5a3b4, 0 0 20px #c5a3b4, 0 0 30px #c5a3b4, 0 0 30px #c5a3b4, 0 0 30px #c5a3b4;
    }
    100% {
      box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #553947, 0 0 20px #553947, 0 0 30px #553947, 0 0 30px #553947, 0 0 30px #553947;
    }
  }

  .leasingCallDisableSpinner{
    .p-progress-spinner-circle{
        stroke: #fff !important;
    }
}

.callDisableSpinner{
  .p-progress-spinner-circle{
      stroke: #000 !important;
      stroke-width: 3 !important;
  }
  .p-progress-spinner-svg{
    width: 50px;
  }
}

//this property has these attributes in order to be hidden because of global <video> being present in the terminal
#initialVideo{
  position: absolute;
  left: -1000%;
}

.cdkOverlayContainerManual {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  padding: 15px 0;
  overflow: auto;
}

.cdkGlobalOverlayWrapperManual {
  display: flex;
  position: unset !important;
  grid-column: span 2;
}

.cdkOverlayPane {
  position: unset !important;
}