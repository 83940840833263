.call-button {
    background-color: #ececec !important;
    color: black !important;
    box-shadow: none !important;
    height: 9vh !important;
    width: 9vh !important;
    
    mat-icon{
        font-size: 2.5em !important; 
    }
}

.call-button::after,
.end-call-button::after {
    padding: 10px;
    background-color: white;
}

.end-call-button {
    background-color: red !important;
    color: white !important;
    box-shadow: none !important;
    height: 9vh !important;
    width: 9vh !important;
    
    mat-icon{
        font-size: 2.5em !important; 
    }
}

.reschedule-call-button::after {
    padding: 10px;
    background-color: grey;
}

.reschedule-call-button {
    background-color: grey !important;
    color: white !important;
    box-shadow: none !important;
    height: 9vh !important;
    width: 9vh !important;
    
    mat-icon{
        font-size: 2.5em !important; 
    }
}
